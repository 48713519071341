<template>
  <form @submit.prevent="doSubmit()">
    <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
      <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
        <router-link
          :to="{ 
            name: !this.id ? 'categories' : 'detail-category', 
            params: this.id ? {id: this.id} : null,
          }"
          class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
        >
          <i class="mr-2 pi pi-arrow-left"></i>
          Kembali
        </router-link>
        <div>
          <Button label="Batal"
            iconPos="left" 
            class="tw-h-9 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
            @click="$router.push({
              name: !this.id ? 'categories' : 'detail-category',
              params: this.id ? {id: this.id} : null,
            })"
          />
          <Button
            type="submit"
            label="Simpan"
            icon="pi pi-check"
            class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          />
        </div>
      </div>
      <h5 class="tw-text-xl inter-semibold tw-text-black">
        {{ !this.id ? 'Tambah' : 'Edit' }} Kategori
      </h5>
    </div>

    <div class="grid tw-justify-center tw-pt-16">
      <div class="col-12 sm:col-10 lg:col-6">
        <div class="input-group tw-mb-6">
          <label class="tw-text-black inter-medium tw-mb-2 block">
            Nama Kategori <span class="tw-text-red-700">*</span>
          </label>
          <InputText type="text" class="w-full" v-model="form.name" />
        </div>

        <div class="input-group tw-mb-6" v-if="this.id">
          <label class="tw-text-black inter-medium tw-mb-2 block">
            Foto Layanan <span class="tw-text-red-700">*</span>
          </label>
          <div class="input-file-wrapper">
            <div class="input-file" v-if="!form.image_preview">
              <Button
                type="button"
                iconPos="left" 
                icon="pi pi-cloud-upload"
                class="tw-h-9 tw-text-sm tw-mb-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
              />
              <p>
                <span class="inter-bold tw-text-blue-light">Klik untuk unggah</span>
                atau seret ke sini
              </p>
              <p>JPG, JPEG, PNG (maks. 2MB)</p>
            </div>
            <div class="preview tw-p-6" v-else>
              <img :src="form.image_preview" alt="">
              <div class="delete" @click="deleteFile()">
                <i class="pi pi-trash tw-text-white"></i>
              </div>
            </div>
            <input type="file" accept=".png, .jpeg, .jpg" @input="onUpload" v-if="!form.image_preview">
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: '',
  props: ['id'],
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/categories/${this.id}`)
        .then(response => {
          this.form = response.data.data
          this.form.image_preview = this.form.image
        })
    },

    onUpload(event) {
      let files = event.target.files
      if (!files.length) return

      let file = files[0]

      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.$toast.add({
          severity:'error', 
          summary: 'Error!', 
          detail: 'File tidak dapat di proses', 
          life: 5000,
        })
        return
      }

      let reader = new FileReader();
      this.form.image = file
      
      reader.readAsDataURL(file);
      reader.onload = function () {
        let result = reader.result
        this.form.image_preview = result

        if (!this.id) return

        let formData = new FormData()
        formData.append('image', this.form.image)

        this.$http
          .post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/categories/${this.id}/upload/image`, formData)
          .then(response => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: response.data.message,
              life: 3000,
            });
          })
          .catch(error => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response.data.message,
              life: 3000,
            });
          })
      }.bind(this);
    },
    deleteFile() {
      this.form.image = null
      this.form.image_preview = null
    },

    doSubmit() {
      let method = this.id ? 'put' : 'post'
      let uri = this.id ? `api/v1/categories/${this.id}` : `api/v1/categories/`

      this.$http[method](`${process.env.VUE_APP_PRITI_MAIN_API}/${uri}`, this.form)
        .then(response => {
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: response.data.message,
            life: 3000,
          })
          this.$router.push({
            name: !this.id ? 'categories' : 'detail-category',
            params: this.id ? {id: this.id} : null,
          })
        })
        .catch(error => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.message,
            life: 3000,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>